<template>
  <div class="container-fluid mt-3">
    <upload-center/>
  </div>
</template>

<script>
import UploadCenter from "@/components/uploadCenter";

export default  {
  name: "uploadCenterPage",
  components: {
    UploadCenter,
  }
}
</script>
